
.contact {
  height: 100%;
  max-width: 1366px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 50px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }

  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 768px) {
      gap: 20px;
      text-align: center;
      align-items: center;
      margin-top: 70px;
    }

    h1 {
      font-size: 100px;
      line-height: 88px;

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }

    h2 {
      font-size: 25px;
    }

    .headingItem {
      font-size: 20px;
    }

    span {
      font-weight: 300;
    }
  }

  .formContainer {
    flex: 1;
    position: relative;
    
    @media (max-width: 768px) {
      padding: 10px;
      width: 100%;

    }

    .phoneSvg {
      stroke: rgb(20, 134, 255);
      position: absolute;
      margin: auto;
      margin-top: 50px;
      z-index: -1;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input,
      textarea {
        padding: 20px;
        background-color: transparent;
        border: 1px solid rgb(3, 3, 3);
        color: rgb(0, 0, 0);
        border-radius: 5px;

        @media (max-width: 768px) {
          padding: 10px;
        }
      }

      button {
        padding: 20px;
        border: none;
        font-size: 20px;
        color: #ffffff;
        border-radius: 30px;
        background-color: rgb(44, 174, 206);
        cursor: pointer;
        font-weight: 500;
        @media (max-width: 768px) {
          padding: 10px;
          font-size: 18px;
        }
      }
    }
  }
}
