@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(103, 170, 252);
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(65, 98, 124);
}