/* carousel.css */

/* Container for the carousel */
.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px; /* Set the desired height for the carousel container */
  }
  
  /* Individual carousel items */
  .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Style the images within the carousel items */
  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }