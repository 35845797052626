
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.8s;
    padding: 45px 30px;
    z-index: 10000;
    color: black;

    .sidebar {
        display: none;
      }
      
      @media (max-width: 768px) {
        .sidebar {
          display: block; 
        }
      }
}


.logo {
    width: 200px;
    height: auto;
    margin-left: 20px;
}

img  {
    width: 20rem;
}

 ul li a:hover {
    color: rgb(68, 68, 216);
  }

p,
a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-weight: 400;
  }

.header-bg {
    background-color: rgb(243, 240, 240);
    transition: 0.8s;
    box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.75);
}

.navbar-bg ul li a{
    color: rgb(0, 0, 0);
}

.navbar-bg ul li a:hover{
    color: rgb(68, 68, 216);
}

.nav-bar .nav-menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; 
    list-style: none;
}

.icon {
    color: #000;;
}

 ul li a {
    position: relative;
    list-style: none;
    position: relative;
    margin: 0 8px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    letter-spacing: 3px;
    font-weight: 500px;
    transition: 0.6s;
    padding: 10px 10px;
    font-size: 23px;
 }

.mobile-menu-icon {
    display: none;
}

@media (max-width:768px) {
    
    .nav-bar .nav-menu {
        display: none;
    }

    .logo {
        padding-left: 50px ;
        padding-top: 10px;
    }

    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
        left: 0;
        top: 10%;
        transition: all 0.5s ease-in-out;
        width: 100%;
        height: 24rem;
        background-color: white;
    }

    ul li {
       padding: 32px;
       width: 100%;
       transition: all 0.5s ease-in-out;
       text-align: center; 
     }
        
    ul li a {
        color: rgb(0, 0, 0);
      }
    
    ul li a:hover {
        color: rgb(186, 141, 49);
      }

        .mobile-menu-icon {
            position: absolute;
            display: block;
            right: 30px;
        }  
}
