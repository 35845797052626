.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;  
}
.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

@function textShadow($precision, $size, $color){
  $value: null;
  $offset: 0;
  $length: $size * (1 / $precision) - 1;

  @for $i from 0 through $length {
    $offset: $offset + $precision;
    $shadow: $offset + px $offset + px $color;
    $value: append($value, $shadow, comma);
  }

  @return $value;
}

.playful span {
  position: relative;
  color: #5653f6;
  text-shadow: textShadow(0.25, 6, #E485F8);
  animation: scatter 1.75s infinite;
}

.playful span:nth-child(2n) {
  color: #21A0B1;
  text-shadow: textShadow(0.25, 6, #F2A063);
  animation-delay: 0.3s;
}

.playful span:nth-child(3n) {
  color: #0F75BD;
  text-shadow: textShadow(0.25, 6, #6EC0A9);
  animation-delay: 0.15s;
}

.playful span:nth-child(5n) {
  color: #555BFF;
  text-shadow: textShadow(0.25, 6, #E485F8);
  animation-delay: 0.4s;
}

.playful span:nth-child(7n), .playful span:nth-child(11n) {
  color: #FF9C55;
  text-shadow: textShadow(0.25, 6, #FF5555);
  animation-delay: 0.25s;
}

@keyframes scatter {
  0% {
    top: 0;
  }
  50% {
    top: -10px;
  }
  100% {
    top: 0;
  }
}
